import { useMutation, useQueryClient } from 'react-query'
import React, { useState, useEffect } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { motion, AnimatePresence } from 'framer-motion';

import Button, { CopyButton } from '../button/Button';
import PopUp from '../popUpVazio/PopUpPersonalizavel'
import PopUpConfirmaRetornoTodos from './PopUpVazio'
import PopUpConfirmaRetorno from '../popUpVazio/PopUpVazio'
import PopUpAlerta from '../popUpAlerta/PopUpAlerta'

import { atualizaStatusRetorno } from '../../api/retornos';

import './popUpRetornosMotorista.scss'

const PopUpRetornosMotorista = ({ trigger, setTrigger, retornos, atualizaRetornos, atualiza }) => {
  const [loader, setLoader] = useState(false);

  const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false)

  const [popUpConfirmaRetornoTodos, setPopUpConfirmaRetornoTodos] = useState(false)
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);
  const [retornouId, setRetornouId] = useState()

  const [step, setStep] = useState(1); 
  const [selectedValue, setSelectedValue] = useState("");

  const saldos = [{saldoAtual: 50, repAgendado: 100}]

  const slideVariants = {
    enter: { x: '100%', opacity: 0 },
    center: { x: 0, opacity: 1 },
    exit: { x: '-100%', opacity: 0 },
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const initialStep = () => {
    setStep(1);
  };


  const lidarComDescConv = () => {
    nextStep()
  }


  const lidarFechar = () => {
    setTrigger(false)
    initialStep()
};


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleRetornou = async (id) => {
    try {
      await atualizaStatusRetorno(id, "suporte");
      await atualizaRetornos();
      setPopUpConfirmaRetorno(false)
      setTrigger(false)
      atualiza(true)
    } catch (error) {
      setPopUpConfirmaRetorno(false)
      setTrigger(false)
      console.error("Erro ao lidar com retorno:", error);
    }
  }

  const handleRetornouTodos = () => {
    try{
      retornos.forEach(element => {
        handleRetornou(element.retorno._id)
        setPopUpConfirmaRetornoTodos(false)
        setTrigger(false)  
      });
      atualiza(true)
    }
    catch(error){
      setPopUpConfirmaRetornoTodos(false)
      setTrigger(false)
      setConteudoPopUp("Erro ao lidar com retornos do motorista. Tente novamente. ");
      setPopUpAlerta(true);
      console.error("Erro ao lidar com retornos do motorista:", error);
    }
  }

  return (
    trigger ?
      <PopUp trigger={trigger} setTrigger={setTrigger}>
        <div className='popUpConteudo' style={{ width: '800px' }}>
          <i className="material-icons"style={{ width: '100%', display:'flex', justifyContent:'flex-end', cursor:'pointer' }}  onClick={() => {lidarFechar()}}>close</i>
            <AnimatePresence mode="wait">
              {step === 1 && (
                <motion.div
                  key="step1"
                  initial="enter"
                  animate="center"
                  exit="exit"
                  variants={slideVariants}
                  transition={{ duration: 0.5 }}
                  className="conteudo"
                >
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SportsMotorsportsOutlinedIcon fontSize="medium" style={{ marginRight: "5px", marginLeft: "15px" }} />
                      <h4>{retornos[0].retorno.motorista}</h4>
                      {retornos[0].retorno.status === "Aguardando" ?
                        < Button
                          text='Retornou Todas'
                          width='130px'
                          borderRadius='8px'
                          border='black'
                          background='#6B4380'
                          color='white'
                          margin="0px 20px 0px auto"
                          onClick={() => { setPopUpConfirmaRetornoTodos(true) }}
                        />
                        : null
                      }
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow className='table-title'>
                          <TableCell><b>Número OS</b></TableCell>
                          <TableCell><b>Empresa</b></TableCell>
                          <TableCell><b>Parada</b></TableCell>
                          <TableCell><b>Data Solicitação</b></TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {retornos.map((retorno, index) => (
                          <LinhaTabela
                            key={index}
                            setPopUpConfirmaRetorno={setPopUpConfirmaRetorno}
                            retorno={retorno.retorno}
                            setRetornouId={setRetornouId}
                            setStep={setStep}
                            step={step}
                          />
                        ))}
                      </TableBody>

                    </Table>
                    </TableContainer>
                  </div>
                </motion.div>
              )}
              {step === 2 && (
                <motion.div
                  key="step2"
                  initial="enter"
                  animate="center"
                  exit="exit"
                  variants={slideVariants}
                  transition={{ duration: 0.5 }}
                  className="conteudo"
                >
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SportsMotorsportsOutlinedIcon fontSize="medium" style={{ marginRight: "5px", marginLeft: "15px" }} />
                      <h4>{retornos[0].retorno.motorista}</h4>
                    </div>

                    <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow className='table-title'>
                          <TableCell><b>Saldo atual</b></TableCell>
                          <TableCell><b>Rep. Agendado</b></TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {saldos.map((retorno, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <label style={{ display: "flex", alignItems: "center", fontSize:'23px', color: 'rgb(66, 175, 60)' }}>
                              <input
                                type="radio"
                                value={index}
                                checked={selectedValue === String(index)}
                                onChange={handleChange}
                                style={{marginRight:'3px'}}
                              />
                              {`R$ ${retorno.saldoAtual},00`}
                            </label>
                          </TableCell>
                          <TableCell>
                          <label style={{ display: "flex", alignItems: "center", fontSize:'23px', color: 'rgb(66, 175, 60)' }}>
                              <input
                                type="radio"
                                value={index}
                                checked={selectedValue === String(index)}
                                onChange={handleChange}
                                style={{marginRight:'3px'}}
                              />
                             {`R$ ${retorno.repAgendado},00`}
                            </label>
                          </TableCell>
                          <TableCell>
                            < Button
                              text='Descontar'
                              width='120px'
                              borderRadius='8px'
                              border='black'
                              background='rgb(66, 175, 60)'
                              color='white'
                              onClick={() => { }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                      </TableBody>

                      </Table>
                    </TableContainer>

                  </div>
                </motion.div>
              )}

              </AnimatePresence>
        </div>



        <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
          {
            <div>
              {conteudoPopUp}
            </div>
          }
        </PopUpAlerta>

        <PopUpConfirmaRetorno trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true} >
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você deseja confirmar esse retorno?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { handleRetornou(retornouId) }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmaRetorno(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmaRetorno>

        <PopUpConfirmaRetornoTodos trigger={popUpConfirmaRetornoTodos} setTrigger={setPopUpConfirmaRetornoTodos} light={true} >
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você deseja confirmar TODOS os retornos desse motorista?"}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={ handleRetornouTodos }
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmaRetornoTodos(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmaRetornoTodos>

      </PopUp>
      : null
  );
};

const LinhaTabela = ({ retorno, setPopUpConfirmaRetorno, setRetornouId, setStep, step }) => {
  const handleConfirmaRetorno = async () => {
    setRetornouId(retorno._id)
    setPopUpConfirmaRetorno(true)
  }

  const lidarComDescConv = () => {
    nextStep()
  }

  const nextStep = () => {
    setStep(step + 1);
  };

  return (<>
    <TableRow key={retorno._id}>
      <TableCell>{retorno.os} <CopyButton link={retorno.os} /></TableCell>
      <TableCell>{retorno.nomeEmpresa}</TableCell>
      <TableCell>{retorno.parada}</TableCell>
      <TableCell>
        {new Date(retorno.data_solicitacao).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })} {new Date(retorno.data_solicitacao).toLocaleTimeString('pt-BR')}
      </TableCell>

      <TableCell>
        {retorno.retorno.dinheiro ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalAtmOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorDinheiro}
          </div>
          : null}
        {retorno.retorno.maquininha ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CreditCardOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorMaquininha} ID: {retorno.retorno.idMaquininha}
          </div>
          : null}
        {retorno.retorno.comandaAssinada ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ReceiptLongOutlinedIcon style={{ marginRight: '5px' }} />
          </div>
          : null}

      </TableCell>
      <TableCell>
        {retorno.status === "Aguardando" ?
          <div style={{display:'flex'}}>
           {/*< Button
            text='Desc. Conv'
            width='120px'
            borderRadius='8px'
            border='black'
            background='#f09226'
            color='white'
            onClick={() => {lidarComDescConv() }}
          /> */}
          < Button
            text='Retornou'
            width='120px'
            borderRadius='8px'
            border='black'
            background='rgb(66, 175, 60)'
            color='white'
            onClick={() => { handleConfirmaRetorno() }}
          />
        </div>
          : null}
      </TableCell>

    </TableRow>
  </>);
}


export default PopUpRetornosMotorista;